<template>
  <div>
    <div class="activity">
      <div class="activity_head">
        <div class="activity_head_left">
          <div class="activity_head_serialNumber">{{ routeRow.serialNumber }}</div>
          <div class="activity_head_time">
            链接失效时间：{{ routeRow.startTime }} 至 {{ routeRow.endTime }}
          </div>
        </div>
        <div class="activity_head_right">
          <div class="activity_head_register">
            <div class="activity_head_register_number">{{ routeRow.applyMemberNumber }}</div>
            <div class="activity_head_register_string">申请会员</div>
          </div>
          <div class="activity_head_conversion">
            <div class="activity_head_register_number">{{ routeRow.lookNumber }}</div>
            <div class="activity_head_register_string">查看人数</div>
            <div class="line"></div>
          </div>

          <div class="activity_head_echarts">
            <div class="pie">
              <el-progress
                type="circle"
                :percentage="routeRow.distributionPercentConversion || 0"
                color="#7C71E1"
                :width="64"
                :hieght="64"
                :stroke-width="8"
              ></el-progress>
            </div>
            <div class="text">分销转化率</div>
          </div>
        </div>
      </div>

      <!-- context -->
      <div class="activity_context">
        <div class="context_inquire">
          <!-- 用户等级 -->
          <div class="Grade">
            <span class="leb">用户等级：</span>
            <span class="span_button" v-for="item in GradeList" :key="item.id">
              <el-button :class="GradeActive == item.id ? 'active' : ''" @click="clickGrade(item)">
                {{ item.value }}
              </el-button>
            </span>
          </div>
          <!-- 沟通状态 -->
          <div class="goutong">
            <span class="leb">沟通状态：</span>
            <span class="span_button" v-for="item in CommunicationStatus" :key="item.id">
              <el-button
                :class="Communication == item.id ? 'active' : ''"
                @click="contactClick(item)"
              >
                {{ item.value }}
              </el-button>
            </span>
          </div>
        </div>

        <!-- 导出和搜索 -->
        <div class="extract_search">
          <!-- 导出 -->
          <div class="extract" @mouseleave="mouseleaveExport">
            <el-button
              :class="extractClickButton == true ? 'deep_button' : 'pure_button'"
              @click="clickExport"
            >
              <img
                v-if="extractClickButton"
                src="../../../../static/images/jr-icon-out-copy.png"
                alt=""
              />
              <img v-else src="../../../../static/images/jr-icon-out-copy (1).png" alt="" />

              批量导出
            </el-button>
          </div>
          <!-- 搜索 -->
          <div class="search">
            <el-input
              v-model="inputVal"
              placeholder="请输入用户昵称，手机号查询"
              clearable
            ></el-input>
            <el-button @click="searchClick" class="deep_button">搜索</el-button>
          </div>
        </div>

        <!-- table表格 -->
        <div class="table">
          <el-table
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            @selection-change="handleSelectionChange"
            :header-cell-style="{ background: '#F6F6F6', color: '#333333' }"
            :row-key="(row) => row.id"
            @sort-change="changeTableSort"
          >
            <el-table-column type="selection" width="55" align="center"> </el-table-column>
            <el-table-column label="编号" align="center" prop="serialNumber">
              <template slot-scope="scope">{{ scope.row.serialNumber }}</template>
            </el-table-column>

            <el-table-column label="沟通状态" align="center" prop="linkStatus">
              <template slot-scope="scope">
                <div v-if="scope.row.linkStatus == 0" class="not">
                  <div class="back_not"></div>
                  <div>未联系</div>
                </div>
                <div v-else class="already">
                  <span class="back_already"></span>
                  <div>已联系</div>
                </div>
              </template>
            </el-table-column>

            <el-table-column label="头像" align="center" prop="headPortrait">
              <template slot-scope="scope">
                <img
                  style="
                     {
                      width: 48px;
                      height: 48px;
                      border-radius: 4px;
                    }
                  "
                  :src="scope.row.headPortrait"
                  alt=""
                />
              </template>
            </el-table-column>
            <el-table-column label="姓名" align="center" prop="userName">
              <template slot-scope="scope">{{ scope.row.userName }}</template>
            </el-table-column>

            <el-table-column label="手机号" align="center" prop="mobile">
              <template slot-scope="scope">{{ scope.row.mobile }}</template>
            </el-table-column>
            <el-table-column label="微信号" align="center" prop="weChatId">
              <template slot-scope="scope">{{ scope.row.weChatId }}</template>
            </el-table-column>

            <el-table-column label="用户等级" align="center" prop="whetherMember">
              <template slot-scope="scope">
                <div v-if="scope.row.whetherMember === 1">普通会员</div>
                <!-- <div v-if="scope.row.whetherMember === 5">超级会员</div> -->
              </template>
            </el-table-column>

            <el-table-column
              label="查看时间"
              sortable="custom"
              align="center"
              width="180"
              prop="lookTime"
            >
              <template slot-scope="scope">{{ scope.row.lookTime }}</template>
            </el-table-column>

            <el-table-column
              label="申请会员时间"
              sortable="custom"
              align="center"
              width="180"
              prop="applyMemberTime"
            >
              <template slot-scope="scope">{{ scope.row.applyMemberTime }}</template>
            </el-table-column>

            <el-table-column
              label="想联系时间"
              sortable="custom"
              align="center"
              width="180"
              prop="thinkContactTime"
            >
              <template slot-scope="scope">{{ scope.row.thinkContactTime }}</template>
            </el-table-column>

            <el-table-column label="操作" align="center" width="180" prop="linkStatus">
              <template slot-scope="scope">
                <div class="to_distribution_list">
                  <span
                    v-if="scope.row.linkStatus == 0"
                    class="start2"
                    @click="clickContact(scope.row)"
                    >未联系</span
                  >
                  <span v-else class="start1">已联系</span>
                  <div @click="ToDistributionList(scope.row)" class="see">查看详情</div>
                </div>
              </template>
            </el-table-column>
          </el-table>

          <!-- 分页 -->
          <div class="Pagination">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[10, 30, 50]"
              :page-size="100"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { selectSupplyDistribution, exportExcelSupply, updateState } from '../../../api/distribution'
export default {
  components: {},
  data() {
    //这里存放数据
    return {
      // 分销管理页面传递过来的数据
      routeRow: {},
      GradeActive: 999, // 用户等级
      Communication: 999, // 沟通状态
      // 用户等级
      GradeList: [
        {
          value: '全部',
          id: 999
        },
        {
          value: '普通会员',
          id: 1
        }
        // {
        //   value: '超级会员',
        //   id: 5
        // }
      ],
      CommunicationStatus: [
        {
          value: '全部',
          id: 999
        },
        {
          value: '未联系',
          id: 0
        },
        {
          value: '已联系',
          id: 1
        }
      ],
      // 元素的类名控制
      extractClickButton: false,
      // 请输入用户昵称，手机号查询的val
      inputVal: '',
      // 活动分销请求发送的数据
      querySearch: {
        current: 1,
        id: 0,
        isSignIn: null,
        nameOrMobile: '',
        size: 10,
        whetherMember: null
      },
      // 表格数据
      tableData: [],
      //总数据条数
      total: 0,
      // 当前页数
      currentPage: 1,
      // 选中的表格id数组
      tableIdList: []
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    clickGrade(item) {
      if (item.id == 999) {
        this.GradeActive = item.id
        this.querySearch.whetherMember = null
        this.search()
      } else {
        this.GradeActive = item.id
        this.querySearch.whetherMember = this.GradeActive
        this.search()
      }
      // console.log(this.GradeActive)
    },
    // 沟通状态
    contactClick(item) {
      if (item.id == 999) {
        this.Communication = item.id
        this.querySearch.isSignIn = null
        this.search()
      } else {
        this.Communication = item.id
        this.querySearch.isSignIn = this.Communication
        this.search()
      }
    },
    // 鼠标移除批量导出元素
    mouseleaveExport() {
      this.extractClickButton = false
    },
    // 批量导出
    async clickExport() {
      // 判断有没有勾选数据
      if (!this.tableIdList.length > 0) {
        return this.$message.warning('请勾选数据')
      }
      // 改变按钮颜色
      this.extractClickButton = true
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const { data: res } = await exportExcelSupply({
        id: this.routeRow.id,
        idList: this.tableIdList
      })
      if (res) {
        loading.close()
        let blob = new Blob([res])
        let href = window.URL.createObjectURL(blob) //创建新的URL表示指定的blob对象
        const a = document.createElement('a') //创建a标签
        a.style.display = 'none'
        a.href = href // 指定下载'链接
        a.download = this.routeRow.userName + '被分销人员列表' + '.xlsx' //指定下载文件名
        // a.download = "test.png";
        a.click() //触发下载
        window.URL.revokeObjectURL(a.href) //释放URL对象
        if (document.body.contains(a)) {
          document.body.removeChild(a) //释放标签
        }
      } else {
        this.$message.error('导出失败!')
        loading.close()
      }
    },
    // 点击搜索
    searchClick() {
      this.querySearch.nameOrMobile = this.inputVal
      this.search()
    },
    // table表格数据
    async search() {
      const { data: res } = await selectSupplyDistribution(this.querySearch)
      if (res.resultCode === 200) {
        this.tableData = res.data.list
        this.total = res.data.total
      }
      // console.log(res)
    },
    //表格按钮点击选中事件
    handleSelectionChange(val) {
      this.multipleSelection = val
      this.tableIdList = []
      this.multipleSelection.forEach((item) => {
        this.tableIdList.push(item.id)
      })
      // console.log(val)
    },
    // 操作->查看详细->要跳转
    ToDistributionList(row) {
      // console.log(row)
      // console.log('查看详情')
      this.$router.push({
        path: '/userManagement/userDetail?id=' + row.id
      })
    },
    // 分页
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`)
      this.querySearch.size = val
      this.search()
    },
    // 分页
    handleCurrentChange(val) {
      this.querySearch.current = val
      this.search()
      // console.log(`当前页: ${val}`)
    },
    // 点击联系用户
    async clickContact(row) {
      const { data: res } = await updateState({
        distributionId: this.routeRow.id,
        userId: row.id
      })
      if (res.resultCode === 200) {
        this.search()
      }
      // console.log(res)
      // console.log(row)
      // console.log('未联系')
    },
    // 表格排序
    changeTableSort(column) {
      // console.log(column)
      // console.log(column.prop, 'prop')
      // console.log(column.order, 'order')
    }
  },

  created() {
    // 分销主页面传递过来的值
    // this.routeRow = JSON.parse(sessionStorage.getItem('routeRow'))
    this.routeRow = JSON.parse(this.$route.query.query)
    this.querySearch.id = this.routeRow.id
    // 调用table数据
    this.search()
    // console.log(this.routeRow.userName)
  },

  mounted() {}
}
</script>
<style scoped lang="less">
.activity {
  padding: 16px 15px 0 23px;
  .activity_head {
    width: 100%;
    height: 121px;
    background-color: #fff;
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    .activity_head_left {
      box-sizing: border-box;
      width: 50%;
      height: 100%;
      padding: 18px 0 16px 18px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .activity_head_serialNumber {
        font-size: 28px;
        color: #7c71e1;
      }
      .activity_head_time {
        font-size: 16px;
        color: #333;
      }
    }
    .activity_head_right {
      width: 50%;
      height: 100%;
      box-sizing: border-box;
      display: flex;
      flex-direction: row-reverse;
      .activity_head_register {
        margin: 0 80px 0 60px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        .activity_head_register_number {
          font-size: 24px;
          color: #7c71e1;
        }
        .activity_head_register_string {
          font-size: 12px;
          color: #333;
        }
      }

      .activity_head_conversion {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        margin-left: 110px;
        .activity_head_register_number {
          font-size: 24px;
          color: #7c71e1;
        }
        .activity_head_register_string {
          font-size: 12px;
          color: #333;
        }
        .line {
          position: absolute;
          left: -60px;
          top: 37px;
          width: 60px;
          height: 38px;
          border-left: 2px solid #ededed;
        }
      }

      .activity_head_echarts {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        .pie {
          width: 64px;
          height: 64px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .text {
          font-size: 12px;
          color: #333;
        }
      }
    }
  }

  .activity_context {
    background-color: #fff;
    box-sizing: border-box;
    width: 100%;
    border-radius: 12px;
    padding: 0 16px 0 20px;
    .context_inquire {
      width: 100%;
      height: 64px;
      border-bottom: 1px dashed #ededed;
      display: flex;
      align-items: center;

      .Grade {
        margin: 0 60px 0 0;
        .leb {
          font-size: 16px;
        }
        .span_button {
          margin-right: 15px;

          .el-button {
            font-size: 14px;
          }
        }
      }

      .goutong {
        .leb {
          font-size: 16px;
        }
        .span_button {
          margin-right: 15px;

          .el-button {
            font-size: 14px;
          }
        }
      }
    }

    .extract_search {
      box-sizing: border-box;
      width: 100%;
      height: 65px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 50px;

      .extract {
        img {
          width: 16px;
          height: 16px;
          margin-right: 3px;
        }

        /deep/ .el-button span {
          display: flex;
          align-items: center;
        }
      }
      .search {
        display: flex;
        align-items: center;
        justify-content: space-between;
        /deep/.el-input__inner {
          width: 382px;
          height: 32px;
        }
        /deep/.el-input__suffix {
          display: flex;
          align-items: center;
        }
        /deep/.el-button {
          margin-left: 16px;
        }
      }
    }

    .table {
      width: 100%;
      // height: 100%;
      padding-bottom: 80px;
      position: relative;

      .to_distribution_list {
        display: flex;
        justify-content: space-around;
        color: #7c71e1;

        .see {
          cursor: pointer;
        }
      }
      .Pagination {
        width: 100%;
        position: absolute;
        bottom: 0;
        display: flex;
        justify-content: center;
        margin-bottom: 30px;

        /deep/.active {
          width: 28px;
          height: 28px;
          border-radius: 2px;
          background-color: #7c71e1;
          color: #fff;
          font-size: 12px;
          min-width: 28px;
          font-weight: 400;
        }
      }

      .not {
        color: #ff7043;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        .back_not {
          width: 8px;
          height: 8px;
          background-color: #ff7043;
          border-radius: 50%;
        }
      }

      .already {
        color: #7c71e1;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        .back_already {
          width: 8px;
          height: 8px;
          background-color: #7c71e1;
          border-radius: 50%;
        }
      }
      .start1 {
        color: #c0c0c0;
        cursor: not-allowed;
      }
      .start2 {
        color: #7c71e1;
        cursor: pointer;
      }
    }
  }
  .active {
    color: #fff !important;
    background-color: #8598ff !important;
  }
  .pure_button {
    border-radius: 4px;
    border: 1px solid #7c71e1;
    color: #7c71e1;
    font-size: 14px;
  }
  .pure_button:hover {
    border-radius: 4px;
    border: 1px solid #7c71e1;
    color: #7c71e1;
    background-color: rgba(124, 113, 225, 0.2);
    font-size: 14px;
  }

  .deep_button {
    border-radius: 4px;
    color: #fff;
    background-color: #7c71e1;
    font-size: 14px;
  }
}
</style>